export default [
    {
        path: '/form',
        name: 'form',
        meta: { title: '表单模板' },
        component: () => import('@/components/RouterView'),
        redirect: '/form/index',
        children: [
          {
            path: 'index',
            name: 'index',
            meta: { title: '表单模板-列表展示' },
            component: () => import('@/view/product/form/index.vue'),
          },
          {
            path: 'deteil',
            name: 'deteil',
            meta: { title: '表单模板-详情' },
            component: () => import('@/view/product/form/component/deteil.vue'),
          },
          {
            path: 'edit',
            name: 'edit',
            meta: { title: '表单模板-编辑' },
            component: () => import('@/view/product/form/component/edit.vue'),
          },
          {
            path: 'add',
            name: 'add',
            meta: { title: '表单模板-编辑' },
            component: () => import('@/view/product/form/component/add.vue'),
          },
        ]
      },
]