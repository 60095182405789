export default (prefix = '') => [
  {
    path: `${prefix}/product`,
    name: `product-${prefix}`,
    meta: { title: '产品' },
    component: () => import('@/components/RouterView'),
    redirect: `${prefix}/product/index`,
    children: [
      {
        path: 'index',
        meta: { title: '产品主页' },
        component: () => import('@/view/product/index'),
      },
      {
        path: 'gobang',
        meta: { title: '五子棋' },
        component: () => import('@/view/product/goBang/component/goBang'),
      },
      {
        path: 'scoring-texas',
        meta: { title: '德州积分器' },
        component: () => import('@/view/product/scoring-texas/index'),
      }, 
      {
        path: 'scoring-bullfight',
        meta: { title: '斗牛积分器' },
        component: () => import('@/view/product/scoring-bullfight/index'),
      }, 
      // {
      //   path: 'werewolfKill',
      //   meta: { title: '狼人杀' },
      //   component: () => import('@/view/product/werewolfKill/component/WerewolfKill'),
      // }
    ]
  },
]