/**
 * 禁用手机端双击放大
 */
export function useDisableZoom() {
    // 禁用缩放
    function addMeta() {
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no";
        document.head.appendChild(meta);
    }
    setTimeout(addMeta, 3000);

    // 禁用双指放大
    document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    }, { passive: false });

    // 禁用双击放大
    let lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
        const now = Date.now();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, { passive: false });
}
