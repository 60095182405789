import { createRouter, createWebHistory } from 'vue-router';
import { useIsMobile } from "@/composables/use-isMobile";
const { isMobile } = useIsMobile();
import productRoutes from './product';
import formRoutes from './form';
import deviceRoutes from './device';

export const routes = [
  {
    path: '',
    name: '',
    meta: { title: '默认进入页面' },
    redirect: () => {
      return isMobile.value ? '/mobile' : '/pc'
    },
  },
  {
    path: '/pc',
    name: 'pc',
    meta: { title: 'PC端UI' },
    component: () => import('@/view/PC/index'),
    redirect: '/pc/homepage',
    children: [
      {
        path: 'homepage',
        name: 'homepage-pc',
        meta: { title: 'PC端首页' },
        component: () => import('@/view/PC/homepage/index'),
      },
      ...productRoutes('/pc')
    ],
    beforeEnter: (to, from, next) => {
      if (isMobile.value) {
        next('/mobile');
      } else {
        next();
      }
    }
  },
  {
    path: '/mobile',
    name: 'mobile',
    meta: { title: 'Mobile端UI' },
    component: () => import('@/view/Mobile/index'),
    redirect: '/mobile/homepage',
    children: [
      {
        path: 'homepage',
        name: 'homepage-mobile',
        meta: { title: 'Mobile端首页' },
        component: () => import('@/view/Mobile/homepage/index'),
      },
      // ...productRoutes('/mobile')
    ]
  },
  ...formRoutes,
  ...deviceRoutes

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
