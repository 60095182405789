<template>
  <div class="homePage">
    <router-view />
    <!-- <ICPfiling /> -->
  </div>
</template>

<script setup>
import { onMounted } from "vue";
// import ICPfiling from "./components/ICPfiling/ICPfiling";
import { useDisableZoom } from "@/composables/use-disable-zoom";
onMounted(() => {
  useDisableZoom();
});
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}

.homePage {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  box-sizing: border-box;
}
</style>
