import { computed, ref, onMounted, onUnmounted } from 'vue';

/**
 * 判断是否为移动端 判断width是否小于768
 */
export function useIsMobile() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value <= 768);
    const updateWindowWidth = () => {
        windowWidth.value = window.innerWidth
    }
    onMounted(() => {
        window.addEventListener('resize', updateWindowWidth);
    })
    onUnmounted(() => {
        window.removeEventListener('resize', updateWindowWidth);
    });
    return { isMobile };
}
